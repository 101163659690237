<template>
  <div class="right-top-main">
    <div class="viode-main">
      <!-- <slider ref="slider" :options="options">
        <slideritem v-for="(item, index) in someList" :key="index">
          <img :src="item.imageUrl" alt="loading..." class="slid-image" />
        </slideritem>
        <div slot="loading">loading...</div>
      </slider> -->

      <video muted autoplay controls="controls" loop="loop" width="100%">
        <source src="../../assets/right-top/yikong.mp4" type="video/mp4" />
      </video>
    </div>
  </div>
</template>

<script>
// import slider components
// import { slider, slideritem } from "vue-concise-slider";

export default {
  components: {
    // slider,
    // slideritem,
  },
  data() {
    return {
      someList: [
        {
          imageUrl: require('../../assets/right-top/00.jpg'),
        },
        {
          imageUrl: require('../../assets/right-top/01.jpg'),
        },
        {
          imageUrl: require('../../assets/right-top/02.jpg'),
        },
        {
          imageUrl: require('../../assets/right-top/03.jpg'),
        },
        {
          imageUrl: require('../../assets/right-top/04.jpg'),
        },
        {
          imageUrl: require('../../assets/right-top/05.jpg'),
        },
        {
          imageUrl: require('../../assets/right-top/06.jpg'),
        },
        {
          imageUrl: require('../../assets/right-top/07.jpg'),
        },
        {
          imageUrl: require('../../assets/right-top/08.jpg'),
        },
        {
          imageUrl: require('../../assets/right-top/09.jpg'),
        },
        {
          imageUrl: require('../../assets/right-top/10.jpg'),
        },
        {
          imageUrl: require('../../assets/right-top/11.jpg'),
        },
        {
          imageUrl: require('../../assets/right-top/12.jpg'),
        },
        {
          imageUrl: require('../../assets/right-top/13.jpg'),
        },
        {
          imageUrl: require('../../assets/right-top/14.jpg'),
        },
        {
          imageUrl: require('../../assets/right-top/15.jpg'),
        },
        {
          imageUrl: require('../../assets/right-top/16.jpg'),
        },
        {
          imageUrl: require('../../assets/right-top/17.jpg'),
        },
        {
          imageUrl: require('../../assets/right-top/18.jpg'),
        },
        {
          imageUrl: require('../../assets/right-top/19.jpg'),
        },
        {
          imageUrl: require('../../assets/right-top/20.jpg'),
        },
        {
          imageUrl: require('../../assets/right-top/21.jpg'),
        },
        {
          imageUrl: require('../../assets/right-top/22.jpg'),
        },
        {
          imageUrl: require('../../assets/right-top/23.jpg'),
        },
        {
          imageUrl: require('../../assets/right-top/24.jpg'),
        },
        {
          imageUrl: require('../../assets/right-top/25.jpg'),
        },
        {
          imageUrl: require('../../assets/right-top/26.jpg'),
        },
        {
          imageUrl: require('../../assets/right-top/27.jpg'),
        },
        {
          imageUrl: require('../../assets/right-top/28.jpg'),
        },
        {
          imageUrl: require('../../assets/right-top/29.jpg'),
        },
        {
          imageUrl: require('../../assets/right-top/30.jpg'),
        },
        {
          imageUrl: require('../../assets/right-top/31.jpg'),
        },
        {
          imageUrl: require('../../assets/right-top/32.jpg'),
        },
        {
          imageUrl: require('../../assets/right-top/33.jpg'),
        },
        {
          imageUrl: require('../../assets/right-top/34.jpg'),
        },
        {
          imageUrl: require('../../assets/right-top/35.jpg'),
        },
        {
          imageUrl: require('../../assets/right-top/36.jpg'),
        },
      ],
      options: {
        // 默认显示第几个图片
        currentPage: 0,
        // 切换间隔时间
        autoplay: '5000',
        // 是否开启循环播放
        loop: true,
      },
    }
  },
}
</script>

<style lang="less" scoped>
// 右上角显示区域
.right-top-main {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // 显示内容区域
  .viode-main {
    border-radius: 15px;
    position: absolute;
    width: 100%;
    height: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -1%;
  }
}

.video {
  width: 100%;
  height: 100%;
}
</style>
