<template>
  <div class="lefttop-main">
    <div class="chian">
      <!-- 页面标题 -->
      <div class="title-bg">客户全国分布图</div>
      <div class="left-tab">
        <!-- 新增用户数 -->
        <div class="view1" style="height: 240px">
          <newAddUser></newAddUser>
        </div>
        <!--服务数字和服务趋势-->
        <div class="view2">
          <el-carousel :interval="5000" arrow="none" direction="vertical">
            <el-carousel-item v-for="item in 1" :key="item">
              <!-- 检测服务趋势图 -->
              <div v-if="item === 1" class="num-box">
                <div class="number-box">
                  <div class="title">服务总人数</div>
                  <div class="lan-num">{{ formatter(headcountTotalNum) }}</div>
                </div>
                <div class="number-box">
                  <div class="title">直接服务人数</div>
                  <div class="lv-num">
                    {{ formatter(directServerTotalNum) }}
                  </div>
                </div>
                <div class="number-box">
                  <div class="title">间接服务人数</div>
                  <div class="cheng-num">
                    {{ formatter(indirectServerTotalNum) }}
                  </div>
                </div>
              </div>

              <!--&lt;!&ndash;检测人数&ndash;&gt;-->
              <!--<div v-if="item === 2" class="num-box">-->
              <!--  <div class="number-box">-->
              <!--    <div class="title2">{{ nowYearName }}年服务总人数</div>-->
              <!--    <div class="lan-num">-->
              <!--      {{ formatter(yearTotals.headcountTotalNum) }}-->
              <!--    </div>-->
              <!--  </div>-->
              <!--  <div class="number-box">-->
              <!--    <div class="title2">{{ nowYearName }}年直接服务人数</div>-->
              <!--    <div class="lv-num">-->
              <!--      {{ formatter(yearTotals.directServerTotalNum) }}-->
              <!--    </div>-->
              <!--  </div>-->
              <!--  <div class="number-box">-->
              <!--    <div class="title2">{{ nowYearName }}年间接服务人数</div>-->
              <!--    <div class="cheng-num">-->
              <!--      {{ formatter(yearTotals.indirectServerTotalNum) }}-->
              <!--    </div>-->
              <!--  </div>-->
              <!--</div>-->
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>

      <!-- 展示中国地图 -->
      <div id="main"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import '../../util/china'
// import env from '../../util/baseurl'
// import http from '../../util/http'
import newAddUser from '../rightbottom/components/newAddUser'
import { serviceNumberFun, serviceNumberMapFun } from '@/api/xiaofeng'

export default {
  components: {
    newAddUser,
  },
  data() {
    return {
      nowYearName: new Date().getFullYear(),
      // 2023年数据
      yearTotals: {
        headcountTotalNum: 123456,
        directServerTotalNum: 123456,
        indirectServerTotalNum: 123456,
      },
      // 服务总人数
      headcountTotalNum: '404640',
      // 直接服务人数
      directServerTotalNum: '202321',
      // 间接服务人数
      indirectServerTotalNum: '202419',
      // 地图数据
      mydata: [
        { name: '北京', value: 14107, addNumber: 11 },
        { name: '内蒙古', value: 501, addNumber: 0 },
        { name: '天津', value: 3472, addNumber: 1 },
        { name: '上海', value: 19381, addNumber: 18 },
        { name: '重庆', value: 3332, addNumber: 8 },
        { name: '河北', value: 4078, addNumber: 4 },
        { name: '河南', value: 2034, addNumber: 1 },
        { name: '云南', value: 2150, addNumber: 8 },
        { name: '辽宁', value: 5640, addNumber: 2 },
        { name: '黑龙江', value: 651, addNumber: 0 },
        { name: '湖南', value: 24525, addNumber: 30 },
        { name: '安徽', value: 3638, addNumber: 1 },
        { name: '山东', value: 30832, addNumber: 13 },
        { name: '新疆', value: 723, addNumber: 3 },
        { name: '江苏', value: 10066, addNumber: 20 },
        { name: '浙江', value: 12354, addNumber: 7 },
        { name: '江西', value: 1774, addNumber: 2 },
        { name: '湖北', value: 7130, addNumber: 12 },
        { name: '广西', value: 1771, addNumber: 5 },
        { name: '甘肃', value: 1057, addNumber: 1 },
        { name: '山西', value: 1526, addNumber: 11 },
        { name: '陕西', value: 2325, addNumber: 2 },
        { name: '吉林', value: 27, addNumber: 0 },
        { name: '福建', value: 6047, addNumber: 3 },
        { name: '贵州', value: 364, addNumber: 0 },
        { name: '广东', value: 23801, addNumber: 2 },
        { name: '青海', value: 0, addNumber: 0 },
        { name: '西藏', value: 0, addNumber: 0 },
        { name: '四川', value: 4078, addNumber: 7 },
        { name: '宁夏', value: 277, addNumber: 2 },
        { name: '海南', value: 2185, addNumber: 2 },
        { name: '台湾', value: 0, addNumber: 0 },
        { name: '香港', value: 0, addNumber: 0 },
        { name: '澳门', value: 0, addNumber: 0 },
      ],
    }
  },
  computed: {
    // // 总人数
    // headcountTotal() {
    //   return [...this.headcountTotalNum].map(item => (item = +item))
    // },
    // // 直接服务人数
    // directServerTotal() {
    //   return [...this.directServerTotalNum].map(item => (item = +item))
    // },
    // // 简介服务人数
    // indirectServerTotal() {
    //   return [...this.indirectServerTotalNum].map(item => (item = +item))
    // },
  },
  mounted() {
    this.getServerNumber()
    // 每隔12小时自动请求一次
    setInterval(() => {
      this.getServerNumber()
    }, 1000 * 60 * 60 * 12)

    // 中国地图配置数据
    var optionMap = {
      // 清空背景颜色
      backgroundColor: '',
      // 循环展示每个地区的数据
      tooltip: {
        trigger: 'item',
        position: function(point, params, dom, rect, size) {
          // 提示框位置
          var x = 0 // x坐标位置
          var y = 0 // y坐标位置
          // 当前鼠标位置
          var pointX = point[0]
          var pointY = point[1]
          // 提示框大小
          var boxWidth = size.contentSize[0]
          var boxHeight = size.contentSize[1]
          // boxWidth > pointX 说明鼠标左边放不下提示框
          if (boxWidth > pointX) {
            x = 5
          } else {
            // 左边放的下
            x = pointX - boxWidth - 5
          }
          // boxHeight > pointY 说明鼠标上边放不下提示框
          if (boxHeight > pointY) {
            y = 5
          } else {
            // 上边放得下
            y = pointY - boxHeight - 5
          }
          return [x, y]
        },
        backgroundColor: 'rgba(63,96,235,0.8)', //设置背景图片 rgba格式
        borderWidth: '1', //边框宽度设置1
        borderColor: '#ffffff', //设置边框颜色
        textStyle: {
          color: '#ffffff', //设置文字颜色
        },
        // 格式化显示的文字
        formatter(params) {
          return `
            <div style='display: flex;align-items: center;gap: 5px;'>
              <div style='width: 10px;height: 10px;border-radius: 5px;background-color: red;'></div>
              <div>${params.name}</div>
            </div>
            <div>服务人数 + ${(params.data && params.data.addNumber) || 0}</div>
          `
        },
      },
      // 图例
      legend: {
        data: this.mydata,
      },
      //左侧小导航图标
      visualMap: {
        show: true,
        x: 'left',
        y: 'center',
        left: '20%',
        top: '75%',
        splitList: [
          { start: 6000 },
          { start: 3000, end: 6000 },
          { start: 1000, end: 3000 },
          { start: 0, end: 1000 },
        ],
        textStyle: {
          //主标题的属性
          color: '#FFFFFF', //颜色
        },
        color: ['#233dab', '#324fcd', '#3f60eb', '#556dd1'],
      },
      //配置属性
      series: [
        {
          name: '客户数量',
          type: 'map',
          mapType: 'china',
          width: '70%',
          // 设置小圆点颜色和大小
          itemStyle: {
            normal: {
              color: '#D71561', // 小圆点颜色
              borderWidth: 1, //设置边框宽度
              borderColor: '#19266b', //设置边框颜色
            },
          },
          data: this.mydata, //数据
        },
      ],
    }
    //初始化echarts实例
    let myChart = echarts.init(document.getElementById('main'))
    //使用制定的配置项和数据显示图表
    myChart.setOption(optionMap)

    // 调用接口获取各个省份的数据
    serviceNumberMapFun().then(res => {
      let provinceList = res.data.info || []

      // 遍历获取到的各个省份数据的集合
      provinceList.forEach(item => {
        // 从中国省份中获取对应的城市信息
        let provinceInfo = this.mydata.find(
          i => item.province.indexOf(i.name) !== -1
        )
        if (provinceInfo) {
          // 如果找到则更新今日新增数据
          this.$set(provinceInfo, 'addNumber', item.increaseNumber)
        }
      })
      // 重新更新地图渲染
      myChart.setOption(optionMap)
    })

    // http.get(`${env.xuyang}/getpeoplelist`).then(res => {
    //   let provinceList = res || []
    //   // 遍历获取到的各个省份数据的集合
    //   provinceList.forEach(item => {
    //     // 从中国省份中获取对应的城市信息
    //     let provinceInfo = this.mydata.find(i => i.name === item.shenfeng)
    //     if (provinceInfo) {
    //       // 如果找到则更新今日新增数据
    //       this.$set(provinceInfo, 'addNumber', item.sum)
    //     }
    //   })
    //   // 重新更新地图渲染
    //   myChart.setOption(optionMap)
    // })

    // 保存this指向
    // let that = this
    // 获取服务总人数的数字
    // let rollNum1 = +this.headcountTotal.join('')
    // 获取间接服务总人数的数字
    // let rollNum2 = +this.directServerTotal.join('')

    // 过滤出新增数不等于0的城市
    let copyData = JSON.parse(JSON.stringify(this.mydata))
    // 获取有新增数的数据
    let noEmptCity = []
    // 循环获取到有新增数的下标
    copyData.forEach((item, index) => {
      if (item.addNumber !== 0) {
        noEmptCity.push({
          cityIndex: index,
        })
      }
    })
    //播放所在下标
    let index = 0
    // 循环的数据总数
    let dataTotal = noEmptCity.length
    //自动播放显示提示
    setInterval(function() {
      let dataIndex
      // 如果下标大于数据总和则数据从头开始
      if (index === dataTotal) {
        index = 0
        // 如果下标等于循环数据的长度，标识循环完了一周，数据从头重新开始增加
        // rollNum1 = +that.headcountTotal.join('')
        // rollNum2 = +that.directServerTotal.join('')
      } else {
        // 获取有新增数据在总数据中的下标
        dataIndex = noEmptCity[index].cityIndex
        // 服务总人数累加
        // rollNum1 += +that.mydata[dataIndex].addNumber
        // 间接服务总人数
        // rollNum2 += +that.mydata[dataIndex].addNumber
      }
      // 获取有新增数据在总数据中的下标
      dataIndex = noEmptCity[index].cityIndex
      // 动态设置服务总人数的数字
      // that.$refs.roll1.toOrderNum(rollNum1);
      // 动态设置间接服务总人数的数字
      // that.$refs.roll2.toOrderNum(rollNum2);
      // 显示指定区域的tip
      myChart.dispatchAction({
        // 显示提示框
        type: 'showTip',
        seriesIndex: 0,
        // 要显示的数据下标
        dataIndex: dataIndex,
      })
      // 两秒下标加一
      index++
    }, 2000)
  },
  methods: {
    // 获取服务人数
    getServerNumber() {
      serviceNumberFun().then(res => {
        console.log(res, '111111111111')

        // 直接服务人数
        this.directServerTotalNum = res.data.info
          .map(item => item.jcServiceNumber)
          .reduce((a, b) => a + b, 0)
        // 间接服务人数
        this.indirectServerTotalNum = res.data.info
          .map(item => item.sjServiceNumber)
          .reduce((a, b) => a + b, 0)

        // 服务总人数
        this.headcountTotalNum =
          this.directServerTotalNum + this.indirectServerTotalNum

        // 2023年服务人数
        let nowYearInfo = res.data.info.find(i => i.year === this.nowYearName)
        this.yearTotals = {
          headcountTotalNum: nowYearInfo.totalServiceNumber || 0, // 服务总人数
          directServerTotalNum: nowYearInfo.jcServiceNumber || 0, // 直接服务总人数
          indirectServerTotalNum: nowYearInfo.sjServiceNumber || 0, // 间接服务总人数
        }
      })
    },

    // // 获取左上角的三个数字
    // getTotalNumbers() {
    //   http.get(`${env.xuyang}/get`).then(res => {
    //     this.headcountTotalNum = res[0] + ''
    //     this.directServerTotalNum = res[1] + ''
    //     this.indirectServerTotalNum = res[2] + ''
    //
    //     this.$refs.roll1.renderNum(this.headcountTotal)
    //     this.$refs.roll2.renderNum(this.directServerTotal)
    //     this.$refs.roll3.renderNum(this.indirectServerTotal)
    //   })
    // },
    // 随机产生数字
    randomData() {
      return Math.round(Math.random() * 2000)
    },
    // 格式化数字样式
    formatter(number) {
      const numbers = number
        .toString()
        .split('')
        .reverse()
      const segs = []
      while (numbers.length) segs.push(numbers.splice(0, 3).join(''))
      return segs
        .join(',')
        .split('')
        .reverse()
        .join('')
    },
  },
}
</script>

<style lang="less" scoped>
// 左上屏样式设置
.lefttop-main {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // 图表展示大小
  .chian {
    // background-color: #2b1653;
    border-radius: 15px;
    position: absolute;
    width: 97%;
    height: 97%;
    // 圆角背景图片
    // background-image: url("../../assets/left-top/1.png");
    background-size: 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: -1%;
    //标题颜色
    .title-bg {
      position: absolute;
      top: 2%;
      left: 0;
      width: 158.5px;
      height: 28.5px;
      // 左上角标题背景图
      background-image: url('../../assets/left-top/0.png');
      font-size: 14px;
      color: #ffffff;
      line-height: 28.5px;
      text-align: center;
    }

    // 地图右侧信息
    .user-total1 {
      position: absolute;
      display: flex;
      flex-direction: column;
      left: 4%;
      top: 20%;
      color: white;
      min-width: 100px;
      max-width: auto;
      border: 1px solid #556dd1;
      padding: 10px;
      border-radius: 5px;

      & > div:nth-child(1) {
        font-size: 14px;
        color: #fb5e80;
        font-weight: 700;
        margin-bottom: 1px;
      }

      & > div:nth-child(2) {
        font-size: 19px;
        color: #deb068;
        font-weight: 700;
      }
    }

    .user-total2 {
      position: absolute;
      display: flex;
      flex-direction: column;
      left: 4%;
      top: 40%;
      color: white;
      min-width: 100px;
      max-width: auto;
      border: 1px solid #556dd1;
      padding: 10px;
      border-radius: 5px;

      & > div:nth-child(1) {
        font-size: 14px;
        color: #fb5e80;
        font-weight: 700;
        margin-bottom: 1px;
      }

      & > div:nth-child(2) {
        font-size: 19px;
        font-weight: 700;
        color: #1a8dec;
      }
    }

    .user-total3 {
      position: absolute;
      display: flex;
      flex-direction: column;
      left: 4%;
      top: 60%;
      color: white;
      min-width: 100px;
      max-width: auto;
      border: 1px solid #556dd1;
      padding: 10px;
      border-radius: 5px;

      & > div:nth-child(1) {
        font-size: 14px;
        color: #fb5e80;
        font-weight: 700;
        margin-bottom: 1px;
      }

      & > div:nth-child(2) {
        font-size: 19px;
        font-weight: 700;
        color: #5ad807;
      }
    }

    .left-tab {
      position: absolute;
      left: 0;
      width: 35%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      color: white;
      flex-direction: column;
      padding-top: 80px;

      .view1 {
        position: absolute;
        width: 100%;
      }

      .view2 {
        position: absolute;
        width: 100%;
        bottom: 36px;
        height: 40%;
      }
    }

    // 地图展示大小
    #main {
      position: absolute;
      right: 0;
      width: 70%;
      height: 100%;
    }
  }
}

.formatter-span {
  width: 5px;
  height: 5px;
  border-radius: 2.5px;
  background-color: red;
  display: flex;
  align-items: center;
  gap: 10px;
}

.num-box {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.number-box {
  display: flex;
  padding: 9px;
  justify-content: space-between;
  border: 1px solid #243587;
  background-color: #1d104e;
  margin: 0 14px;
  border-radius: 5px;
  height: 40px;
  align-items: center;
}

.title {
  font-size: 16px;
  color: #fb5e80;
  font-weight: 700;
}

.title2 {
  color: #7ca5ee;
  margin-top: 3px;
  font-weight: 700;
}

.lan-num {
  color: #7ca5ee;
  font-size: 19px;
  font-weight: 700;
}

.lv-num {
  color: #5ad807;
  font-size: 19px;
  font-weight: 700;
}

.cheng-num {
  color: #deb068;
  font-size: 19px;
  font-weight: 700;
}

.mini-text {
  font-size: 14px;
}

::v-deep .el-carousel__indicators--vertical {
  display: none;
}
</style>
