<template>
  <!-- 最外层元素 -->
  <div id="app">
    <!-- 左上屏 -->
    <div class="left-top">
      <leftTop></leftTop>
    </div>
    <!-- 右上屏 -->
    <div class="right-top">
      <rightTop></rightTop>
    </div>
    <!-- 左下屏 -->
    <div class="left-bottom">
      <!--<rightBottom></rightBottom>-->
      <NewLeftBottom />
    </div>
    <!-- 右下屏 -->
    <div class="right-bottom">
      <leftBottom></leftBottom>
    </div>
  </div>
</template>

<script>
import leftTop from './view/lefttop/index.vue'
import rightTop from './view/righttop'
import leftBottom from './view/leftbottom'
// import rightBottom from './view/rightbottom'
import NewLeftBottom from '@/view/newLeftBottom'
import * as echarts from 'echarts'

export default {
  name: 'App',
  components: {
    leftTop,
    rightTop,
    leftBottom,
    // rightBottom,
    NewLeftBottom,
  },
  mounted() {
    // 多个图表自适应
    window.onresize = function() {
      setTimeout(() => {
        // 中国地图
        echarts.init(document.getElementById('main')).resize()
        // 3D地球
        echarts.init(document.getElementById('global-main')).resize()
        // 检测服务趋势图
        // echarts.init(document.getElementById('detectmap')).resize()
        // 试剂盒趋势图
        // echarts.init(document.getElementById('top3')).resize()
        // 国内用户TOP10
        // echarts.init(document.getElementById('topview')).resize()
        // 服务人数趋势图
        echarts.init(document.getElementById('mapview')).resize()
      })
    }
  },
}
</script>
<style>
body {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: myfamily;
  src: url('./assets/PingFangSC.ttf');
}
</style>
<style scoped>
/* 设置最外层元素，宽高都是百分之百 */
#app {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #000000;
  font-family: myfamily;
}

#app::after {
  content: '';
  background-image: url('./assets/bg.png');
  opacity: 0.8;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

/* 左上角样式设置 */
.left-top {
  position: fixed;
  left: 0;
  top: 0;
  width: 50%;
  height: 50%;
  overflow: hidden;
}

/* 右上角样式设置 */
.right-top {
  position: fixed;
  right: 0;
  top: 0;
  width: 50%;
  height: 50%;
  overflow: hidden;
}

/* 左下角设置 */
.left-bottom {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 50%;
  height: 50%;
  overflow: hidden;
}

/* 右下角设置 */
.right-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 50%;
  height: 50%;
  overflow: hidden;
}
</style>
