<template>
  <div class="leftbottom-main">
    <div class="glob">
      <!-- 区域标题 -->
      <div class="glob-title">全球服务情况</div>
      <!-- 底部的背景图片 -->
      <div class="glob-bottom-image"></div>

      <!-- 地球网格显示 -->
      <img class="glob-reseau" src="../../assets/glob.png" alt="" />

      <!-- 地球边框显示 -->
      <img
        class="glob-border"
        src="../../assets/left-bottom/glob-border.png"
        alt=""
      />

      <!-- 地球显示 -->
      <div id="global-main"></div>
    </div>

    <!--地球四周的四个数字-->
    <div v-if="false">
      <!--全球收入年增长比-->
      <div class="left-top">
        <div>全球收入年增长比</div>
        <div>
          <span class="left-top-text">{{ lt }}</span>
          <span>%</span>
        </div>
      </div>

      <!--全球服务产品年增长比-->
      <div class="right-top">
        <div>全球服务产品年增长比</div>
        <div>
          <span class="right-top-text">{{ rt }}</span>
          <span>%</span>
        </div>
      </div>

      <!--全球试剂收入年增长比-->
      <div class="left-bottom">
        <div>全球试剂收入年增长比</div>
        <div>
          <span class="left-bottom-text">{{ lb }}</span>
          <span>%</span>
        </div>
      </div>

      <!--全球PGT收入增长比-->
      <div class="right-bottom">
        <div>全球PGT收入增长比</div>
        <div>
          <span class="right-bottom-text">{{ rb }}</span>
          <span>%</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import 'echarts-gl'
import { gsap } from 'gsap'
import { globalDataFun } from '@/api/xiaofeng'
import { getJingWei } from '@/util/jingweidu'

export default {
  data() {
    return {
      lt: 18.5,
      rt: 18.5,
      lb: 18.5,
      rb: 18.5,
    }
  },
  async mounted() {
    // 获取元素
    let dom = document.getElementById('global-main')
    // 初始化echarts
    let myChart = echarts.init(dom)
    // 地球数据显示
    let ds = [
      {
        name: '中国',
        value: '381727',
        point: [116.46, 39.92],
      },
      {
        name: '印度',
        value: '302',
        point: [77.103403, 28.703536],
      },
      {
        name: '柬埔寨',
        value: '1693',
        point: [104.927237, 11.567834],
      },
      {
        name: '台湾',
        value: '71',
        point: [121.096209, 23.770639],
      },
      {
        name: '新加坡',
        value: '12',
        point: [103.86008, 1.356423],
      },
      {
        name: '阿拉伯',
        value: '6',
        point: [54.375764, 24.466822],
      },
      {
        name: '美国',
        value: '751',
        point: [-77.036871, 38.901577],
      },
      {
        name: '阿根廷',
        value: '212',
        point: [-58.378684, -34.599643],
      },
      {
        name: '泰国',
        value: '598',
        point: [100.489222, 13.750544],
      },
      {
        name: '巴西',
        value: '162',
        point: [-47.890131, -15.79207],
      },
      {
        name: '西班牙',
        value: '80',
        point: [-3.709539, 40.418313],
      },
      {
        name: '日本',
        value: '6',
        point: [139.767187, 35.720305],
      },
      {
        name: '俄罗斯',
        value: '297',
        point: [37.615297, 55.755987],
      },
      {
        name: '哥伦比亚',
        value: '360',
        point: [-74.075541, 4.717623],
      },
      {
        name: '印度尼西亚',
        value: '13',
        point: [106.843727, -6.206848],
      },
      {
        name: '越南',
        value: '170',
        point: [105.697668, 20.968179],
      },
      {
        name: '德国',
        value: '11',
        point: [13.402079, 52.520181],
      },
      {
        name: '韩国',
        value: '3',
        point: [126.961296, 37.594675],
      },
      {
        name: '蒙古',
        value: '169',
        point: [106.901572, 47.918095],
      },
      {
        name: '香港',
        value: '6',
        point: [114.167719, 22.284268],
      },
      {
        name: '捷克',
        value: '21',
        point: [14.43665, 50.076462],
      },
      {
        name: '法国',
        value: '28',
        point: [2.3321, 48.865151],
      },
      {
        name: '马来西亚',
        value: '52',
        point: [101.68628, 3.139292],
      },
      {
        name: '瑞典',
        value: '2500',
        point: [18.063124, 59.330534],
      },
      {
        name: '澳门',
        value: '7',
        point: [113.572286, 22.142552],
      },
    ]

    let globData = await globalDataFun()

    console.log(globData, 'globDataglobData')

    ds = []

    let allJw = getJingWei()
    globData.data.info.forEach(item => {
      let find = allJw[item.regionName]
      if (find) {
        ds.push({
          name: item.regionName,
          value: item.saleAmount,
          point: find,
        })
      }
    })

    // 点配置信息
    let series = ds.map(item => {
      return {
        name: item.name, // 是否显示左上角图例
        type: 'scatter3D',
        coordinateSystem: 'globe',
        blendMode: 'lighter',
        symbolSize: 8, // 点位大小
        itemStyle: {
          color: '#f00', // 各个点位的颜色设置
          opacity: 1, // 透明度
          borderWidth: 0.5, // 边框宽度
          borderColor: 'rgba(255,255,255)', //rgba(180, 31, 107, 0.8)
        },
        label: {
          show: true, // 是否显示字体
          position: 'left', // 字体位置。top、left、right、bottom
          // formatter: `${item.name}•${this.toThousandsFormates2(item.value)}`, // 具体显示的值
          formatter: `${item.name}`, // 具体显示的值
          textStyle: {
            color: '#a8dcf0', // 字体颜色
            fontFamily: 'sans-serif', // 字体格式
            fontSize: 16, // 字体大小
          },
        },
        data: [item.point], // 数据来源
      }
    })

    // 添加上面的配置项到地球上
    myChart.setOption({
      // 图例设置
      legend: {
        show: false, // 是否显示图例
        selectedMode: 'multiple',
        x: 'right',
        y: 'bottom',
        data: ds.map(item => {
          return item.name // 数据来源
        }),
        padding: [0, 50, 50, 0], // 填充位置,上、右、下、左
        orient: 'vertical', // 排列方式，vertical:垂直排列
        textStyle: {
          color: '#fff', // 文字颜色
        },
      },
      // 地球背景色
      //   backgroundColor: "#2E2677",
      // 地球参数设置
      globe: {
        baseTexture: require('../../assets/bg4.jpg'), // 地球表面覆盖的图片,可以替换成自己想要的图片
        shading: 'color', // 地球中三维图形的着色效果
        viewControl: {
          autoRotate: true, // 是否开启视角绕物体的自动旋转查看
          autoRotateSpeed: 10, //物体自转的速度,单位为角度 / 秒，默认为10 ，也就是36秒转一圈。
          autoRotateAfterStill: 2, // 在鼠标静止操作后恢复自动旋转的时间间隔,默认 3s
          rotateSensitivity: 2, // 旋转操作的灵敏度，值越大越灵敏.设置为0后无法旋转。[1, 0]只能横向旋转.[0, 1]只能纵向旋转
          targetCoord: [116.46, 39.92], // 定位到北京
          maxDistance: 200,
          minDistance: 200,
        },
      },
      // 地球文字显示信息配置
      series: series,
    })

    setInterval(() => {
      let el1 = document.querySelector('.left-top-text')
      this.numScroll(el1, this.lt)

      let el2 = document.querySelector('.right-top-text')
      this.numScroll(el2, this.rt)

      let el3 = document.querySelector('.left-bottom-text')
      this.numScroll(el3, this.lb)

      let el4 = document.querySelector('.right-bottom-text')
      this.numScroll(el4, this.rb)
    }, 5000)
  },
  methods: {
    toThousandsFormates2(num) {
      // 判断传进来的数字是否为非空数字
      if (!isNaN(parseFloat(num))) {
        return Number(Number(num).toFixed(2)).toLocaleString('zh', {
          minimumFractionDigits: 2,
        })
      } else {
        return ''
      }
    },
    // 格式化数字样式
    formatter(number) {
      const numbers = number
        .toString()
        .split('')
        .reverse()
      const segs = []
      while (numbers.length) segs.push(numbers.splice(0, 3).join(''))
      return segs
        .join(',')
        .split('')
        .reverse()
        .join('')
    },
    // 数字滚动特效
    numScroll(el, end) {
      let t1 = gsap.timeline()
      let start = 0
      t1.fromTo(
        el,
        {
          innerText: start,
        },
        {
          x: 50,
          innerText: end,
          duration: 1.5,
        }
      )
    },
  },
}
</script>

<style lang="less" scoped>
// 左上屏样式设置
.leftbottom-main {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // 左下屏幕
  .glob {
    // background-color: #2b1653;
    border-radius: 15px;
    position: absolute;
    width: 97%;
    height: 97%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: -1%;
    // 区域标题
    .glob-title {
      position: absolute;
      top: 2%;
      left: 0;
      width: 158.5px;
      height: 28.5px;
      // 左上角标题背景图
      background-image: url('../../assets/left-top/0.png');
      font-size: 14px;
      color: #ffffff;
      line-height: 28.5px;
      text-align: center;
    }

    // 地球底部背景图
    .glob-bottom-image {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 243.5px;
      background-image: url('../../assets/left-bottom/glob-bottom-bg.png');
      background-size: 100% 100%;
    }

    // 网格图片
    .glob-reseau {
      position: absolute;
      width: 37%;
      opacity: 0.5;
      z-index: 1;
    }

    // 地球边框
    .glob-border {
      position: absolute;
      width: 45%;
      opacity: 0.8;
      z-index: 1;
    }

    // 地球大小
    #global-main {
      width: 80%;
      height: 80%;
      position: absolute;
    }
  }

  // 四周的四个数字
  .left-top {
    position: absolute;
    left: 37px;
    top: 64px;
    color: white;
  }

  .right-top {
    position: absolute;
    right: 37px;
    top: 64px;
    color: white;
  }

  .left-bottom {
    position: absolute;
    bottom: 67px;
    left: 37px;
    color: white;
  }

  .right-bottom {
    position: absolute;
    bottom: 67px;
    right: 37px;
    color: white;
  }
}
</style>
