<template>
  <div class="root1">
    <div class="root-view">
      <div class="title">新增用户数</div>
      <!-- 第二行的小字描述信息 -->
      <div class="desc"></div>
      <div class="content">
        <!-- 日期选择页签 -->
        <div class="time-tabs" ref="timeTab">
          <div
            :style="{
              backgroundImage:
                'url(' + (coverImgUrl === 0 ? timeActiveBg : timeBg) + ')',
            }"
          >
            昨日
          </div>
          <div
            :style="{
              backgroundImage:
                'url(' + (coverImgUrl === 1 ? timeActiveBg : timeBg) + ')',
            }"
          >
            本周
          </div>
          <div
            :style="{
              backgroundImage:
                'url(' + (coverImgUrl === 2 ? timeActiveBg : timeBg) + ')',
            }"
          >
            本月
          </div>
          <!-- <div
            :style="{
              backgroundImage:
                'url(' + (coverImgUrl === 3 ? timeActiveBg : timeBg) + ')',
            }"
          >
            一年
          </div> -->
        </div>
        <!-- 新增数据展示 -->
        <div class="add-total">
          <div class="nunber-title">
            <span v-show="coverImgUrl === 0">昨日新增用户数</span>
            <span v-show="coverImgUrl === 1">本周新增用户数</span>
            <span v-show="coverImgUrl === 2">本月新增用户数</span>
            <!-- <span v-show="coverImgUrl === 3">一年新增用户数</span> -->
          </div>
          <div class="number-view">
            <div v-for="(item, index) in total" :key="index">
              {{ item }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import env from '../../../util/baseurl'
// import http from './../../../util/http'
import {
  serviceNumberLastDayFun,
  serviceNumberLastMonthFun,
  serviceNumberLastWeekFun,
} from '@/api/xiaofeng'

export default {
  data() {
    return {
      coverImgUrl: 0,
      total: [],
      timeBg: require('../../../assets/right-bottom/view1/titlebg.png'),
      timeActiveBg: require('../../../assets/right-bottom/view1/titleactive.png'),
      numbers: [196, 2503, 13696],
    }
  },
  mounted() {
    this.total = (this.numbers[0] + '').split('')
    // 自动切换日期背景颜色
    this.changeTimeBg()
    // 获取昨日新增数据
    this.getBeforDayAddUser()
  },
  methods: {
    // 获取昨日新增人数
    getBeforDayAddUser() {
      try {
        // 请求昨日
        serviceNumberLastDayFun().then(res => {
          console.log('昨日', res.data.info)
          this.numbers[0] = res.data.info || 0
        })
        // 请求本周
        serviceNumberLastWeekFun().then(res => {
          console.log('本周', res.data.info)
          this.numbers[1] = res.data.info || 0
        })
        // 请求本月
        serviceNumberLastMonthFun().then(res => {
          console.log('本月', res.data.info)
          this.numbers[2] = res.data.info || 0
        })
      } catch (err) {
        console.log(err)
      }
    },
    // 随机产生一个数字
    randomData() {
      return Math.round(Math.random() * 10000)
    },
    changeTimeBg() {
      let index = 0
      setInterval(() => {
        if (index === 3) {
          index = 0
        }
        if (index === 0) {
          this.total = (this.numbers[0] + '').split('')
        }
        if (index === 1) {
          this.total = (this.numbers[1] + '').split('')
        }
        if (index === 2) {
          this.total = (this.numbers[2] + '').split('')
        }
        this.coverImgUrl = index
        index++
        // let total = "" + this.randomData();
        // this.total = total.split("");
      }, 2000)
    },
  },
}
</script>

<style lang="less" scoped>
.root1 {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .root-view {
    width: 90%;
    height: 90%;

    .title {
      font-size: 17px;
      color: #ffffff;
    }

    .desc {
      font-size: 12px;
      color: #ffffff;
      line-height: 20px;
    }

    .content {
      position: relative;
      width: 100%;
      height: 65%;
      top: 18px;
      text-align: center;
      background-image: url('../../../assets/right-bottom/view1/bg1.png');
      background-size: 100% 100%;
      // 日期页签
      .time-tabs {
        display: flex;
        position: absolute;
        top: 10px;
        left: 5px;
        gap: 5px;

        & div {
          font-size: 10px;
          width: 42px;
          height: 25px;
          text-align: center;
          line-height: 25px;
          background-size: 100% 100%;
        }
      }

      // 新增数据展示
      .add-total {
        position: absolute;
        top: 30%;
        width: 100%;
        text-align: center;

        .nunber-title {
          font-size: 14px;
          color: #4a66d6;
        }

        .number-view {
          margin-top: 5%;
          display: flex;
          width: 100%;
          justify-content: center;
          gap: 5px;

          & div {
            width: 35px;
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 36px;
            color: #ffffff;
            line-height: 20px;
            background-image: url('../../../assets/right-bottom/view1/number-bg.png');
            border-radius: 5px;
            background-size: 100% 100%;
          }
        }
      }
    }
  }
}
</style>
