// 封装axios
import axios from "axios";

// 创建axios实例
const service = axios.create({
    timeout: 200000, // 设置请求超时时间
    headers: {
        "Content-Type": "application/json;charset=utf-8",
    },
});

// 添加请求拦截器
service.interceptors.request.use(
    function (config) {
        // 在发送请求之前做些什么
        return config;
    },
    function (error) {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

// 添加响应拦截器
service.interceptors.response.use(
    function (response) {
        if (response.status === 200) {
            // 接口正常返回200
            return Promise.resolve(response.data);
        } else if (response.status === 401) {
            // 登录状态已过期，您可以继续留在该页面，或者重新登录
        } else {
            // 否则的话抛出错误
            return Promise.reject(response);
        }
    }
);

export default service;