var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"root1"},[_c('div',{staticClass:"root-view"},[_c('div',{staticClass:"title"},[_vm._v("新增用户数")]),_c('div',{staticClass:"desc"}),_c('div',{staticClass:"content"},[_c('div',{ref:"timeTab",staticClass:"time-tabs"},[_c('div',{style:({
            backgroundImage:
              'url(' + (_vm.coverImgUrl === 0 ? _vm.timeActiveBg : _vm.timeBg) + ')',
          })},[_vm._v(" 昨日 ")]),_c('div',{style:({
            backgroundImage:
              'url(' + (_vm.coverImgUrl === 1 ? _vm.timeActiveBg : _vm.timeBg) + ')',
          })},[_vm._v(" 本周 ")]),_c('div',{style:({
            backgroundImage:
              'url(' + (_vm.coverImgUrl === 2 ? _vm.timeActiveBg : _vm.timeBg) + ')',
          })},[_vm._v(" 本月 ")])]),_c('div',{staticClass:"add-total"},[_c('div',{staticClass:"nunber-title"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.coverImgUrl === 0),expression:"coverImgUrl === 0"}]},[_vm._v("昨日新增用户数")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.coverImgUrl === 1),expression:"coverImgUrl === 1"}]},[_vm._v("本周新增用户数")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.coverImgUrl === 2),expression:"coverImgUrl === 2"}]},[_vm._v("本月新增用户数")])]),_c('div',{staticClass:"number-view"},_vm._l((_vm.total),function(item,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(item)+" ")])}),0)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }