<template>
  <div class="root5-main">
    <div class="bazaar-main">
      <div class="title">服务人数趋势图</div>
      <div class="prop-map">
        <div id="mapview"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { serviceNumberFun } from '@/api/xiaofeng'

export default {
  name: 'bazaarPropBar',
  data() {
    return {
      dataList: [
        {
          year: '2015',
          totalServiceNumber: '49736',
        },
        {
          year: '2016',
          totalServiceNumber: '49736',
        },
        {
          year: '2017',
          totalServiceNumber: '49736',
        },
        {
          year: '2018',
          totalServiceNumber: '49736',
        },
        {
          year: '2019',
          totalServiceNumber: '138495',
        },
        {
          year: '2020',
          totalServiceNumber: '260728',
        },
        {
          year: '2021',
          totalServiceNumber: '381645',
        },
        {
          year: '2022',
          totalServiceNumber: '381645',
        },
        {
          year: '2023(至今)',
          totalServiceNumber: '81645',
        },
      ],
      // 新的一年4月份之后再显示当年数据
      monthHidden: new Date().getMonth() + 1 >= 12,
    }
  },
  methods: {
    async getData() {
      let that = this
      let chartDom = document.getElementById('mapview')
      let myChart = echarts.init(chartDom, 'dark')
      myChart.clear()
      let res = await serviceNumberFun()
      this.dataList = res.data.info || []

      let nowMonth = new Date().getMonth() + 1
      if (nowMonth < 4) {
        this.dataList.pop()
      }

      let xAxisData = []
      let seriesData = []
      this.dataList.forEach(item => {
        seriesData.push(item.totalServiceNumber)
        if (item.year === new Date().getFullYear()) {
          if (this.monthHidden) {
            xAxisData.push(item.year + '(至今)')
          }
        } else {
          xAxisData.push(item.year)
        }
      })
      let barOption = {
        backgroundColor: '', //设置无背景色
        grid: {
          top: '5%',
          left: '3%',
          right: '2%',
          bottom: '2%',
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(63,96,235,0.8)', //设置背景图片 rgba格式
          borderWidth: '1', //边框宽度设置1
          borderColor: '#ffffff', //设置边框颜色
          textStyle: {
            color: '#ffffff', //设置文字颜色
          },
          axisPointer: {
            lineStyle: {
              width: 1,
            },
          },
        },
        xAxis: {
          type: 'category',
          data: xAxisData,
          axisLabel: {
            // 设置x轴间隔
            interval: 0,
            // x轴文字倾斜
            rotate: 0,
          },
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            data: seriesData,
            barWidth: 40,
            type: 'bar',
            itemStyle: {
              color: '#1a8dec',
            },
          },
        ],
      }
      myChart.setOption(barOption)
      let dataIndex = 0
      //自动播放显示提示
      setInterval(function() {
        if (dataIndex >= that.dataList.length) {
          dataIndex = 0
        }
        // 显示指定区域的tip
        myChart.dispatchAction({
          // 显示提示框
          type: 'showTip',
          seriesIndex: 0,
          // 要显示的数据下标
          dataIndex: dataIndex,
        })
        // 两秒下标加一
        dataIndex++
      }, 2000)
    },
  },
}
</script>

<style lang="less" scoped>
.root5-main {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 23px;
  box-sizing: border-box;

  .bazaar-main {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .title {
      font-size: 17px;
      color: #ffffff;
    }

    .desc {
      font-size: 12px;
      color: white;
      line-height: 20px;
    }

    .prop-map {
      margin-top: 20px;
      flex: 1;

      #mapview {
        height: 100%;
        width: 100%;
      }
    }
  }
}
</style>
