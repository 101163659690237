import request from '../util/http'
import env from '../util/baseurl'

// 大屏年服务人数 (返回从2019年开始到当前年的数据)
export function serviceNumberFun() {
  return request({
    url: env.xiaofeng + '/report/salesIndex/dp/serviceNumber',
    method: 'get',
  })
}

// 国内服务人数Top10
export function serviceNumberCnFun() {
  return request({
    url: env.xiaofeng + '/report/salesIndex/dp/serviceNumber/cn',
    method: 'get',
  })
}

// 国际服务人数Top10
export function serviceNumberOverSeaFun() {
  return request({
    url: env.xiaofeng + '/report/salesIndex/dp/serviceNumber/overSea',
    method: 'get',
  })
}

// 2023年服务人数实时运营数据
export function serviceNumberDataFun() {
  return request({
    url: env.xiaofeng + '/report/salesIndex/dp/serviceNumber/data',
    method: 'get',
  })
}

// 地图
export function serviceNumberMapFun() {
  return request({
    url: env.xiaofeng + '/report/salesIndex/dp/serviceNumber/map',
    method: 'get',
  })
}

// 昨日
export function serviceNumberLastDayFun() {
  return request({
    url: env.xiaofeng + '/report/salesIndex/dp/serviceNumber/lastDay',
    method: 'get',
  })
}

// 本周
export function serviceNumberLastWeekFun() {
  return request({
    url: env.xiaofeng + '/report/salesIndex/dp/serviceNumber/lastWeek',
    method: 'get',
  })
}

// 本月
export function serviceNumberLastMonthFun() {
  return request({
    url: env.xiaofeng + '/report/salesIndex/dp/serviceNumber/lastMonth',
    method: 'get',
  })
}

// 海外数据
export function globalDataFun() {
  return request({
    url: env.xiaofeng + '/report/salesIndex/dp/serviceNumber/GlobalData',
    method: 'get',
  })
}
