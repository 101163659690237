<template>
  <div class="lb-box">
    <!--左上-->
    <div class="lt">
      <!--国内服务人数Top10-->
      <GuoNeiTop10 ref="table1" />
    </div>
    <!--右上-->
    <div class="rt">
      <!--国际服务人数Top10-->
      <GuoJiTop10 ref="table2" />
    </div>
    <!--左下-->
    <div class="lb">
      <!-- 服务人数趋势图 -->
      <bazaar-prop-bar ref="table3" />
    </div>
    <!--右下-->
    <!--<div class="rb">-->
    <!--  <FuWuYunYing ref="table4" />-->
    <!--</div>-->
  </div>
</template>

<script>
// import bazaarProp from '../rightbottom/components/bazaarProp'
import GuoNeiTop10 from '@/view/newLeftBottom/comp/GuoNeiTop10'
import GuoJiTop10 from '@/view/newLeftBottom/comp/GuoJiTop10'
import BazaarPropBar from '@/view/rightbottom/components/bazaarPropBar'
// import FuWuYunYing from '@/view/newLeftBottom/comp/FuWuYunYing'

export default {
  name: 'index',
  components: {
    BazaarPropBar,
    // FuWuYunYing,
    GuoJiTop10,
    GuoNeiTop10,
    // bazaarProp,
  },
  mounted() {
    this.$refs.table3.getData()
    // this.$refs.table4.getData()
    this.$refs.table1.getData()
    this.$refs.table2.getData()
  },
}
</script>

<style scoped lang="less">
.lb-box {
  position: relative;
  width: 100%;
  height: 100%;

  .lt {
    position: absolute;
    width: 50%;
    height: 60%;
    left: 0;
    top: 0;
  }

  .rt {
    position: absolute;
    width: 50%;
    height: 60%;
    right: 0;
    top: 0;
  }

  .lb {
    position: absolute;
    width: 100%;
    height: 40%;
    left: 0;
    bottom: 0;
  }

  .rb {
    position: absolute;
    width: 50%;
    height: 40%;
    right: 0;
    bottom: 0;
  }
}
</style>
